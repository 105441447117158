import { AppTheme } from "../theme";
import { BusinessCategory } from "./types/business";
import { ResidenceCategory } from "./types/residence";

export const getBusinessStatusColor = (theme: AppTheme, status: BusinessCategory) => {
  switch (status) {
    case "Closed":
      return theme.colors.closedMarkerColor;
    case "Active":
      return theme.colors.activeMarkerColor;
    case "Data Review":
      return theme.colors.dataReviewMarkerColor;
    case "Field Review":
      return theme.colors.fieldReviewMarkerColor;
    case "Home Based":
      return theme.colors.homeBasedColor;
    default:
      return theme.colors.newMarkerColor;
  }
};

export const getResidenceStatusColor = (theme: AppTheme, status: ResidenceCategory) => {
  switch (status) {
    case "BUSINESS":
      return theme.colors.residentBusinessMarkerColor;
    case "IRREV TRUST":
      return theme.colors.irrevTrustMarkerColor;
    case "REVOC TRUST":
      return theme.colors.revocTrustMarkerColor;
    case "TRUST":
      return theme.colors.trustMarkerColor;
    case "MULTIPLE HOMES":
      return theme.colors.multipleHomesMarkerColor;
    case "MULTIPLE IN STATE":
      return theme.colors.multipleInStateMarkerColor;
    case "MULTIPLE OUT STATE":
      return theme.colors.multipleOutStateMarkerColor;
  }
};