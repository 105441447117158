import { useCallback } from "react";
import { View } from "react-native";

import { useAppSelector } from "../../redux/hooks";
import {
  selectAssigneesFilterResidences,
  selectLoadingResidences
} from "../../redux/residenceListSlice";
import { Residence } from "../../lib/types/residence";

import CommonList from "../SubjectList/CommonList";
import FilterButton from "./FilterButton";
import ResidencePreview from "./ResidencePreview";
import Search from "./Search";
import AssigneeFilterButton from "../shared/AssigneeFilter";

export default function ResidencesList() {
  const isLoading = useAppSelector(selectLoadingResidences);
  const filteredResidences = useAppSelector(selectAssigneesFilterResidences);

  const renderResidence = useCallback((item: Residence) => {
    return <ResidencePreview key={item.id} residence={item} />;
  }, []);

  return (
    <>
      <View
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: 16,
          marginBottom: 24,
          marginRight: 16
        }}
      >
        <Search />
        <FilterButton />
        <AssigneeFilterButton />
      </View>
      <CommonList
        subjectType="Residence"
        isLoading={isLoading}
        subjects={filteredResidences}
        renderSubject={renderResidence}
      />
    </>
  );
}
