import { useCallback, useEffect, useState } from "react";
import { useApolloClient } from "@apollo/client";

import { ScrollView, StyleSheet, View } from "react-native";

import { Text, useTheme } from "react-native-paper";

import { Attachment, Business, Note, Organization, User } from "../../types";

import { selectUser } from "../../redux/userSlice";
import { selectOrganization } from "../../redux/organizationSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import partialNotify from "../../lib/partialNotify";
import useBusinessFromRoute from "../../hooks/useBusinessFromRoute";
import useAppNavigation from "../../hooks/useAppNavigation";
import ActionFlow from "../shared/ActionFlow";
import FormattedAddress from "../shared/FormattedAddress";
import { GPlacesActionItem } from "../shared/GPlacesLink";
import BusinessInformation from "../Business/Information";
import { createBusinessCase, dismissBusinessCase } from "../../Firebase/queries/cases";
import BusinessNotes from "./BusinessNotes";
import BusinessAttachments from "./BusinessAttachments";
import { NavigationContainer } from "@react-navigation/native";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import {
  subscribeAttachments,
  subscribeBusinessNotes,
} from "../../Firebase/queries/businesses";
import { subscribeBusinessToFirestore } from "../../redux/businessListSlice";

import BusinessChip from "../shared/BusinessChip";
import { useAssignee } from "../../hooks/useAssignee";
import { getAttachmentUrl } from "../../Firebase/queries/attachments";
import { AssigneeText } from "../shared/AssigneeText";
import {
  confirmCreateCaseMessage,
  confirmDismissMessage,
} from "../../lib/createDismissCaseHelpers";

export type BusinessCaseInput = {
  category: string;
  status: string;
};

type BusinessCaseProps = {
  business: Business;
  organization: Organization;
  user: User;
};

const Tabs = createMaterialTopTabNavigator();

const createConfirmation = partialNotify();
const dismissConfirmation = partialNotify();

const stylesheet = StyleSheet.create({
  chipWrapper: {
    right: 10,
    left: 0,
    top: 0,
    marginBottom: 5,
  },
  tabViewContainer: {
    minHeight: 500,
  },
  tabView: {
    minHeight: 500,
  },
  tabNav: {
    minHeight: 500,
    marginTop: 24,
  },
  tabScreen: {
    backgroundColor: "transparent",
  },
});

const BusinessCaseForm = ({ business, organization, user }: BusinessCaseProps) => {
  const theme = useTheme();
  const apolloClient = useApolloClient();
  const navigation = useAppNavigation();
  const orgId = organization?.id;
  const busId = business?.id;
  const dispatch = useAppDispatch();
  const [notes, setNotes] = useState<Note[]>([]);
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const assignee = useAssignee(business.assigneeId);

  const handleCreateCase = useCallback(() => {
    createBusinessCase({
      orgId: organization.id,
      business,
      user,
      apolloClient,
    })
      .then(() => {
        navigation.navigate("Business_List");
        createConfirmation("Case created");
      })
      .catch((error) => {
        console.error("Error creating case", error);
      });
  }, [navigation, business, user, apolloClient, organization]);

  const handleDismissCase = useCallback(() => {
    dismissBusinessCase({
      orgId: organization.id,
      businessId: business.id,
    })
      .then(() => {
        navigation.navigate("Business_List");
        dismissConfirmation("Case dismissed");
      })
      .catch((error) => {
        console.error("Error dismissing case", error);
      });
  }, [navigation, business, organization]);

  const { id } = business;

  const editButtonPress = useCallback(() => {
    navigation.navigate("Edit_Business", { id });
  }, [navigation, id]);

  const getUrl: (attachment: Attachment) => Promise<Attachment> = (attachment) => {
    return getAttachmentUrl(attachment).then((downloadUrl) => ({
      ...attachment,
      downloadUrl,
    }));
  };

  useEffect(() => {
    if (!orgId || !busId) return;

    const notesSub = subscribeBusinessNotes(orgId, busId).subscribe(setNotes);
    const bizSub = dispatch(subscribeBusinessToFirestore(orgId));

    const attachmentsSub = subscribeAttachments(orgId, busId).subscribe(
      (attachmentUrls) => {
        const withUrls = attachmentUrls.map(getUrl);
        Promise.all(withUrls).then(setAttachments);
      }
    );

    return () => {
      bizSub.unsubscribe();
      notesSub.unsubscribe();
      attachmentsSub.unsubscribe();
    };
  }, [dispatch, orgId, busId]);

  return (
    <View
      style={{
        backgroundColor: theme.colors.background,
        paddingHorizontal: 25,
        height: "100%",
        flex: 1,
      }}
    >
      <ScrollView>
        <Text style={{ marginBottom: 6, fontSize: 22, fontWeight: "bold" }}>
          {business.name}
        </Text>
        <View style={[stylesheet.chipWrapper, { alignSelf: "flex-start" }]}>
          <BusinessChip category={business.category} />
        </View>
        <AssigneeText assignee={assignee} />

        <FormattedAddress address={business} />
        <ActionFlow>
          <ActionFlow.Item text="Edit" icon="file-edit-outline" onPress={editButtonPress}>
            Edit
          </ActionFlow.Item>
          <ActionFlow.Item
            confirmMessage={confirmCreateCaseMessage}
            onPress={handleCreateCase}
            mode="outlined"
            icon="file-document-outline"
          >
            Create Case
          </ActionFlow.Item>
          <ActionFlow.Item
            text="Dismiss"
            icon="close-circle"
            confirmMessage={confirmDismissMessage}
            onPress={handleDismissCase}
            mode="outlined"
          >
            Dismiss Case
          </ActionFlow.Item>
          <GPlacesActionItem
            address={business?.address}
            placeId={business?.metadata.Bus_Place_ID}
          />
        </ActionFlow>

        <BusinessInformation business={business} />
        <View>
          <NavigationContainer
            documentTitle={{
              // This feels like a hack. Ensure this sub-navigator doesn't update
              // the page title to something like "Related" or "Notes".
              formatter: () => "Digital Canvas | Residence Insights",
            }}
            independent
          >
            <Tabs.Navigator
              initialRouteName="Related"
              style={stylesheet.tabNav}
              sceneContainerStyle={stylesheet.tabScreen}
              screenOptions={{
                tabBarActiveTintColor: theme.colors.primary,
                tabBarStyle: {
                  backgroundColor: theme.colors.background,
                },
                tabBarLabelStyle: {
                  color: theme.colors.onBackground,
                },
              }}
            >
              <Tabs.Screen name="Notes">
                {() => (
                  <ScrollView>
                    <BusinessNotes business={business} notes={notes} />
                  </ScrollView>
                )}
              </Tabs.Screen>

              <Tabs.Screen name="Attachments">
                {() => (
                  <ScrollView>
                    <BusinessAttachments business={business} attachments={attachments} />
                  </ScrollView>
                )}
              </Tabs.Screen>
            </Tabs.Navigator>
          </NavigationContainer>
        </View>
      </ScrollView>
    </View>
  );
};

export default function BusinessBackWrapper() {
  const organization = useAppSelector(selectOrganization);
  const user = useAppSelector(selectUser);
  const business = useBusinessFromRoute();

  if (!business || !organization || !user) {
    return <></>;
  }

  return <BusinessCaseForm business={business} organization={organization} user={user} />;
}
