
export const confirmCreateCaseMessage =
  "Creating a case will allow you to continue " +
  "working this case in Case Manager. However, doing so will remove it from " +
  "the list in Digital Canvas. The case will open in Case Manager in a " +
  "new window. Make sure you don't have popups blocked from Digital Canvas.";

export const confirmDismissMessage =
  "Are you sure you want to dismiss this case? " +
  "Doing so will remove it from the list.";

