import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";
import { Firestore, collection, getDocs } from "firebase/firestore";
import { pick } from "lodash";

import {
  AddressInput,
  ContactInput,
  NoteInput,
  AttachmentInput,
  CaseInput,
  SubjectInput,
  ComplexCaseInput,
  CaseStatus,
} from "./auditawareTypes";

import { Business, Residence, Subject, SubjectNameType, User } from "../types";

interface CreateArgs {
  db: Firestore;
  orgId: string;
  user: User;
  subject: Subject;
  business?: Business;
  residence?: Residence;
  epochId?: string;
  apolloClient: ReturnType<typeof useApolloClient>;
}

type CreateSubjectWithCaseResult = {
  createSubjectWithCase: {
    subject: {
      id: string;
    };
    case: {
      id: string;
    };
  };
};

type ExportAttributes = {
  subjectType: string;
  externalId: string | undefined;
  parcelId: string | undefined;
  googlePlaceId: string | undefined;
  email: string;
  phoneNumber: string;
  website: string;
  googleBusinessId: string | undefined;
  firstAuditYear: string | number;
  subjectNameType: SubjectNameType;
};

type DocumentPathArgs = {
  subjectNameType: SubjectNameType;
  subjectId: string;
  orgId: string;
  epochId?: string;
};

const CREATE_SUBJECT = gql`
  mutation CreateSubjectWithCase(
    $input: ComplexCaseInput!
  ) {
    createSubjectWithCase(input: $input) {
      subject {
        id
      }
      case {
        id
      }
    }
  }
`;

const emptyContact: ContactInput = {
  primaryName: "",
  secondaryName: "",
  address: "",
  city: "",
  stateAbbr: "",
  zipCode: "",
  phoneNumber: "",
  email: "",
};

const formatEmail = (email: unknown) => {
  if (typeof email === "string") {
    return email;
  }

  if (Array.isArray(email) && email.length > 0) {
    return email[0];
  }
  return null;
};

const extractAttributesforExport = (
  business?: Business,
  residence?: Residence
): ExportAttributes => {
  if (business) {
    return extractAttributesFromBusiness(business);
  } else if (residence) {
    return extractAttributesFromResidences(residence);
  }

  throw new Error("No business or residence provided");
};

const extractAttributesFromBusiness = (business: Business): ExportAttributes => {
  return {
    subjectNameType: "Business",
    subjectType: "Organization",
    externalId: business.metadata.Unique_ID,
    parcelId: business.metadata.Parcel_ID,
    firstAuditYear: business.yearBusinessOpened,
    email: formatEmail(business.email),
    phoneNumber: business.phoneNumbers[0] || "",
    website: business.websites[0] || "",
    googlePlaceId: business.metadata.Res_Place_ID,
    googleBusinessId: business.metadata.Bus_Place_ID,
  };
};

const extractAttributesFromResidences = (residence: Residence): ExportAttributes => {
  return {
    subjectNameType: "Residence",
    subjectType: "Residence",
    externalId: residence.uniqueId,
    parcelId: residence.parcelId,
    email: "",
    phoneNumber: "",
    website: "",
    firstAuditYear: "",
    googlePlaceId: residence.parcelId,
    googleBusinessId: undefined,
  };
};

const getDocumentPath = ({
  subjectNameType,
  subjectId,
  orgId,
  epochId,
}: DocumentPathArgs) => {
  const subjectLower = subjectNameType.toLowerCase();
  const epochName = `${subjectLower}_epochs`;
  const collectionName = subjectLower === "business" ? "businesses" : `${subjectLower}s`;
  const organizationPath = `organizations/${orgId}`;
  const subjectPath = `${collectionName}/${subjectId}`;
  const epochPath = `${epochName}/${epochId}`;

  return epochId
    ? `${organizationPath}/${epochPath}/${subjectPath}`
    : `${organizationPath}/${subjectPath}`;
};

const getNoteDocs = async (db: Firestore, documentPath: string) => {
  const notesRef = collection(db, `${documentPath}/notes`);
  const noteDocs = await getDocs(notesRef);
  return noteDocs.docs.map(
    (doc) =>
      ({
        ...pick(doc.data(), "text"),
      }) as NoteInput
  );
};

const getAttachmentDocs = async (db: Firestore, documentPath: string) => {
  const attachmentsRef = collection(db, `${documentPath}/attachments`);
  const attachmentDocs = await getDocs(attachmentsRef);
  return attachmentDocs.docs.map((doc) => {
    const { id } = doc;
    const {
      url,
      // If there's no file name, use the ID.
      fileName = id,
      // If there's no content type, use a generic binary type.
      contentType = "application/octet-stream",
      label: description,
    } = doc.data();

    return {
      id,
      url,
      fileName,
      contentType,
      description,
    } as AttachmentInput;
  });
};

const exportSubjectToCaseManager = async ({
  db,
  orgId,
  subject,
  business,
  residence,
  epochId,
  apolloClient,
}: CreateArgs) => {
  const {
    subjectType,
    externalId,
    parcelId,
    googlePlaceId,
    email,
    phoneNumber,
    website,
    googleBusinessId,
    firstAuditYear,
    subjectNameType,
  } = extractAttributesforExport(business, residence);

  const subjectAddress: AddressInput = {
    address: subject.address,
    city: subject.city,
    stateAbbr: subject.stateAbbr,
    zipCode: subject.zipCode,
  };

  const subjectInput: SubjectInput = {
    subjectType,
    sourceId: subject.id,
    externalId,
    firstAuditYear,
    location: {
      ...subjectAddress,
      parcelId,
      latitude: parseFloat(String(subject.latitude)),
      longitude: parseFloat(String(subject.longitude)),
      googlePlaceId,
      googleBusinessId,
    },
    contact: {
      ...subjectAddress,
      primaryName: subject.name,
      secondaryName: "",
      phoneNumber,
      email,
      website,
    },
  };

  const documentPath = getDocumentPath({
    subjectNameType,
    subjectId: subject.id,
    orgId,
    epochId,
  });
  const noteInputs = await getNoteDocs(db, documentPath);
  const attachmentInputs = await getAttachmentDocs(db, documentPath);

  const caseInput: CaseInput = {
    caseType: "Audit",
    status: CaseStatus.ToDo,
    assigneeUid: subject.assigneeId,
    primaryContact: emptyContact,
    secondaryContact: emptyContact,
  };

  const input: ComplexCaseInput = {
    orgId,
    subjectInput,
    caseInput,
    noteInputs,
    attachmentInputs,
  };

  const result = await apolloClient.mutate<CreateSubjectWithCaseResult>({
    mutation: CREATE_SUBJECT,
    variables: { input },
  });

  if (result.errors) {
    console.error(result.errors);
    throw new Error(result.errors[0].message);
  }

  if (!result.data) {
    console.error("No data returned from createSubject");
    throw new Error("Case created, but cannot open. Please check Case Manager.");
  }
  return result.data.createSubjectWithCase;
};

export default exportSubjectToCaseManager;
