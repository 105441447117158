// NOTE: The Residence counterpart to this component has been moved to
// components/ResidenceList/. This File should probably receive the save
// treatment.

import { useCallback, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Avatar, Text } from "react-native-paper";

import { useAppTheme } from "../../theme";
import { useAppSelector } from "../../redux/hooks";
import {
  selectBusinessResults,
  selectLoadingBusinesses
} from "../../redux/businessListSlice";
import BusinessChip from "../../components/shared/BusinessChip";
import { Business } from "../../lib/types/business";

import CommonList from "./CommonList";
import { useAssignee } from "../../hooks/useAssignee";
import { AssigneeText } from "../shared/AssigneeText";

type BusinessCardProps = {
  business: Business;
};

const stylesheet = StyleSheet.create({
  cardInner: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: "transparent",
  },
  chipWrapper: {
    right: 0,
    top: 0,
    marginBottom: 5,
    maxWidth: "100%",
  },
  businessName: {
    fontWeight: "bold",
    maxWidth: "65%",
    marginBottom: 6,
  },
  idLine: {
    marginTop: 6,
  },
});

const StyledText = (props: { [key: string]: unknown; children: React.ReactNode }) => {
  const theme = useAppTheme();
  const { onBackground } = theme.colors;
  const styles = useMemo(() => ({ color: onBackground }), [onBackground]);

  return <Text {...props} style={styles} />;
};

const BusinessCard: React.FC<BusinessCardProps> = ({ business }: BusinessCardProps) => {
  const theme = useAppTheme();
  const { onBackground } = theme.colors;

  const parcelId = business.metadata?.Parcel_ID || null;
  const uniqueId = business.metadata?.Unique_ID || null;

  const assignee = useAssignee(business.assigneeId);

  return (
    <View style={stylesheet.cardInner}>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Avatar.Icon icon="store" color={onBackground} style={stylesheet.avatar} />
      </View>
      <View style={{ flexGrow: 2 }}>
        <Text style={[stylesheet.businessName, { color: onBackground }]}>
          {business.name}
        </Text>
        <View style={[stylesheet.chipWrapper, { alignSelf: "flex-start" }]}>
          <BusinessChip category={business.category} />
        </View>
        <AssigneeText assignee={assignee} />
        <StyledText>{business.address}</StyledText>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <StyledText>{business.city}, </StyledText>
          <StyledText>{business.stateAbbr} </StyledText>
          <StyledText>{business.zipCode}</StyledText>
        </View>
        {parcelId && (
          <View style={stylesheet.idLine}>
            <StyledText>{parcelId}</StyledText>
          </View>
        )}
        {uniqueId && (
          <View style={stylesheet.idLine}>
            <StyledText>{uniqueId}</StyledText>
          </View>
        )}
      </View>
    </View>
  );
};

export default function BusinessList() {
  const isLoading = useAppSelector(selectLoadingBusinesses);
  const filteredBusinesses = useAppSelector(selectBusinessResults);

  const renderBusiness = useCallback(
    (item: Business) => <BusinessCard key={item.id} business={item} />,
    []
  );

  return (
    <CommonList
      subjectType="Business"
      isLoading={isLoading}
      subjects={filteredBusinesses}
      renderSubject={renderBusiness}
    />
  );
}
