// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";

import { initializeAuth } from "@firebase/auth";

import { getReactNativePersistence } from "./persistence";

import Constants from "expo-constants";
import { connectAuthEmulator } from "firebase/auth";

const {
  firebaseApiKey: apiKey,
  firebaseAuthDomain: authDomain,
  firebaseProjectId: projectId,
  firebaseStorageBucket: storageBucket,
  firebaseMessagingSenderId: messagingSenderId,
  firebaseAppId: appId,
  firebaseMeasurementId: measurementId,
} = Constants.expoConfig?.extra ?? {};

export const firebaseConfig = {
  apiKey,
  authDomain,
  projectId,
  storageBucket,
  messagingSenderId,
  appId,
  measurementId,
};

// Initialize Firebase/Firestore
const app = initializeApp(firebaseConfig);
const auth = initializeAuth(app, {
  persistence: getReactNativePersistence(AsyncStorage),
});

export const storage = getStorage(app);
export const db = getFirestore();

// NOTE: You must also configure SUBContextProvider to use the emulator.
// TODO: Use the Firestore objects from SUBContext instead.
const emulatorHost = Constants.expoConfig?.extra?.emulatorHost;
if (emulatorHost) {
  console.log("Connecting to Firebase emulator at", emulatorHost);
  connectAuthEmulator(auth, `http://${emulatorHost}:9099`);
  connectFirestoreEmulator(db, emulatorHost, 8080);
  connectStorageEmulator(storage, emulatorHost, 9199);
}

export { auth };
