import { ComponentProps, useMemo } from "react";
import { StyleSheet, View } from "react-native";
import { Avatar, Text } from "react-native-paper";

import ResidenceChip from "../../components/shared/ResidenceChip";
import { Residence } from "../../lib/types/residence";
import { useAppTheme } from "../../theme";
import { useAssignee } from "../../hooks/useAssignee";
import { AssigneeText } from "../shared/AssigneeText";


type ResidencePreviewProps = {
  residence: Residence;
};

const stylesheet = StyleSheet.create({
  cardInner: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  avatar: {
    backgroundColor: "transparent",
  },
  chipWrapper: {
    marginBottom: 6,
  },
  residenceName: {
    fontWeight: "bold",
    marginBottom: 6,
  },
  idLine: {
    marginTop: 6,
  },
});

const StyledText = (props: ComponentProps<typeof Text>) => {
  const { onBackground } = useAppTheme().colors;
  const styles = useMemo(() => ({ color: onBackground }), [onBackground]);

  return <Text {...props} style={styles} />;
};

const ResidencePreview: React.FC<ResidencePreviewProps> = ({ residence }) => {
  const { onBackground } = useAppTheme().colors;
  const parcelId = residence.parcelId || null;
  const uniqueId = residence.uniqueId || null;

  const assignee = useAssignee(residence.assigneeId);

  return (
    <View style={stylesheet.cardInner}>
      <View style={{ display: "flex", flexDirection: "column" }}>
        <Avatar.Icon icon="home" color={onBackground} style={stylesheet.avatar} />
      </View>

      <View style={{ alignItems: "flex-start", flexShrink: 1 }}>
        <Text style={[stylesheet.residenceName, { color: onBackground }]}>
          {residence.name}
        </Text>

        <View style={stylesheet.chipWrapper}>
          <ResidenceChip category={residence.category} />
        </View>

        <AssigneeText assignee={assignee} />
        <StyledText>{residence.address}</StyledText>
        <View style={{ display: "flex", flexDirection: "row" }}>
          <StyledText>{residence.city}, </StyledText>
          <StyledText>{residence.stateAbbr} </StyledText>
          <StyledText>{residence.zipCode}</StyledText>
        </View>
        {parcelId && (
          <View style={stylesheet.idLine}>
            <StyledText>{parcelId}</StyledText>
          </View>
        )}
        {uniqueId && (
          <View style={stylesheet.idLine}>
            <StyledText>{uniqueId}</StyledText>
          </View>
        )}
      </View>
    </View>
  );
};

export default ResidencePreview;
